/* Common Imports */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import Paper from "@material-ui/core/Paper";
import PlainText from "../sub-components/PlainText";
import PhoneNumber from "../sub-components/PhoneNumber";
import EmailAddress from "../sub-components/EmailAddress";
import TextArea from "../sub-components/TextArea";
import Primary from "../sub-components/Primary";
import Grid from "@material-ui/core/Grid";
import CheckBox from "../sub-components/Checkbox";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

/* Data Imports */
import AppConfig from "../../AppConfig";
import { WarningRounded } from "@material-ui/icons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bottomMargin: {
    marginBottom: "4em",
  },
  button: {
    marginTop: "1rem",
    // background: "linear-gradient(to right, #2b5876, #4e4376)",
    background: "#4648A0",
    "&:hover": {
      // background: "linear-gradient(to right, #2b5876, #4e4376)",
      background: "#4648A0",
    },
  },
  grey: {
    color: "#202020",
    fontSize: "0.9rem",
  },
  link: {
    color: "#037DBB",
    textDecoration: "none",
    fontSize: "0.9rem",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "1.5rem",
  },
  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}));

const ContactForm = ({ groupId, submitCallback }) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [name, setName] = React.useState(undefined);
  const [phone, setPhone] = React.useState(undefined);
  const [email, setEmail] = React.useState(undefined);
  const [wardName, setWardName] = React.useState(undefined);
  const [address, setAddress] = React.useState(undefined);
  const [grievance, setGrievance] = React.useState(undefined);
  const [agree, setAgree] = React.useState(false);

  const [btnDisabled, setBtnDisabled] = React.useState(true);

  const [formValid, setFormValid] = React.useState(true);
  const [formValidName, setFormValidName] = React.useState(true);
  const [formValidPhone, setFormValidPhone] = React.useState(true);
  const [formValidEmail, setFormValidEmail] = React.useState(true);
  const [formValidWardName, setFormValidWardName] = React.useState(true);
  const [formValidAddress, setFormValidAddress] = React.useState(true);
  const [formValidGrievance, setFormValidGrievance] = React.useState(true);
  const [formValidTc, setFormValidTc] = React.useState(false);

  const checkAllFields = () => {
    if (
      !formValidName &&
      !formValidPhone &&
      !formValidEmail &&
      !formValidWardName &&
      !formValidAddress &&
      !formValidGrievance &&
      !formValidTc
    )
      setFormValid(false);
    else setFormValid(true);
  };

  const checkStateName = (event, error) => {
    setName(event.target.value);
    checkBtnDisabled();
    if (error) setFormValidName(true);
    else setFormValidName(false);
    checkAllFields();
  };
  const checkStatePhone = (event, error) => {
    setPhone(event.target.value);
    checkBtnDisabled();
    if (error) setFormValidPhone(true);
    else setFormValidPhone(false);
    checkAllFields();
  };
  const checkStateEmail = (event, error) => {
    setEmail(event.target.value);
    checkBtnDisabled();
    if (error) setFormValidEmail(true);
    else setFormValidEmail(false);
    checkAllFields();
  };
  const checkStateWardName = (event, error) => {
    setWardName(event.target.value);
    checkBtnDisabled();
    if (error) setFormValidWardName(true);
    else setFormValidWardName(false);
    checkAllFields();
  };
  const checkStateAddress = (event, error) => {
    setAddress(event.target.value);
    //  checkBtnDisabled();
    if (error) setFormValidAddress(true);
    else setFormValidAddress(false);
    checkAllFields();
  };
  const checkStateGrievance = (event, error) => {
    setGrievance(event.target.value);
    //  checkBtnDisabled();
    if (error) setFormValidGrievance(true);
    else setFormValidGrievance(false);
    checkAllFields();
  };

  const checkStateTc = (event) => {
    // if (event.target.checked == false) {
    //   setAgree(true);
    // } else {
    //   setAgree(false);
    // }
    setAgree(event.target.checked);
    // console.log(event.target.checked + " event.target");
    // console.log(agree + " agree");
    //  checkBtnDisabled();
    if (!event.target.checked) setFormValidTc(true);
    else setFormValidTc(false);
    checkAllFields();
  };

  const checkBtnDisabled = () => {
    if (
      name !== undefined &&
      phone !== undefined &&
      email !== undefined
      // wardName !== undefined &&
      // address !==undefined &&
      // grievance !== undefined
      // agree == true
    ) {
      setBtnDisabled(false);
    } else if (
      name == undefined ||
      phone == undefined ||
      email == undefined
      // wardName !== undefined ||
      // address !==undefined ||
      // grievance !== undefined
      // agree == false
    ) {
      setBtnDisabled(true);
    }
  };

  const submitForm = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "store-lead?name=" +
        name +
        "&phone=" +
        phone +
        "&email=" +
        email +
        "&wardname=" +
        wardName +
        "&address=" +
        address +
        "&grievance=" +
        grievance +
        "&agree=" +
        agree
    );
    setLoadingSnackbar(true);
    fetch(reqURL)
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            setLoadingSnackbar(false);
            setSuccessSnackbar(true);
            if (typeof submitCallback === "function") submitCallback(data);
            /* TODO: Send Slack message with lead */
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
            if (typeof submitCallback === "function") submitCallback(error);
            /* TODO: Send Slack message with error */
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
        if (typeof submitCallback === "function") submitCallback(error);
        /* TODO: Send Slack message with error */
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Sending information...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Thanks, {name}! We'll get in touch shortly.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something seems to have gone askew!
        </Alert>
      </Snackbar>
      <Paper elevation={3} className={classes.paper}>
        <Grid
          container
          className={classes.root}
          spacing={2}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <PlainText
              id={groupId + "-name"}
              name={"name"}
              label="Name"
              required={true}
              hc={checkStateName}
            />
          </Grid>

          <Grid item xs={12}>
            <PhoneNumber
              id={groupId + "-phone"}
              name={"phone"}
              label="Phone number"
              required={true}
              hc={checkStatePhone}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailAddress
              id={groupId + "-email"}
              name={"email"}
              label="Email "
              hc={checkStateEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <PlainText
              id={groupId + "-ward-name"}
              name={"ward-name"}
              label="Ward Name"
              required={true}
              hc={checkStateWardName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              id={groupId + "-address"}
              name={"address"}
              label="Address"
              rows="2"
              maxLength="360"
              minLength="2"
              hc={checkStateAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              id={groupId + "-grievance"}
              name={"grievance"}
              label="Grievance / Feedback"
              rows="2"
              required={true}
              maxLength="160"
              minLength="2"
              hc={checkStateGrievance}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <TextArea
              id={groupId + "-grievance"}
              name={"grievance"}
              label="Comments"
              rows="2"
              required={true}
              maxLength="160"
              minLength="2"
              hc={checkStateComment}
            />
          </Grid> */}

          <Grid item xs={12}>
            <CheckBox
              id={groupId + "-agree"}
              name={"agree"}
              onChange={checkStateTc}
              label={
                <span>
                  <span className={classes.grey}>
                    I have read and agree with the{" "}
                  </span>
                  {/* <Link
                    className={classes.link}
                    href="https://beegru.com/terms"
                    aria-label="Terms of Service"
                    title="Terms of Service"
                    rel="noopener"
                  > */}
                  Terms of Service
                  {/* </Link> */} and{" "}
                  {/* <Link
                    className={classes.link}
                    href="https://beegru.com/privacy"
                    aria-label="Terms of Service"
                    title="Terms of Service"
                    rel="noopener"
                  > */}
                  Privacy Policy
                  {/* </Link> */}.
                </span>
              }
            />
          </Grid>

          <Grid item xs={12} className={classes.center}>
            <Primary
              id={groupId + "-submit"}
              className={classes.button}
              variant="contained"
              size="large"
              label="Submit"
              // disabled={btnDisabled}
              onClick={submitForm}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default ContactForm;
