import React from "react";
import { withRouter } from "next/router";
import ImageGallery from "react-image-gallery";
import styles from "react-image-gallery/styles/css/image-gallery.css";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const images = [
  {
    original: "/images/gallery/jobfair/1.webp",
    thumbnail: "/images/gallery/jobfair/1.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
  },
  {
    original: "/images/gallery/jobfair/2.webp",
    thumbnail: "/images/gallery/jobfair/2.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
  },
  {
    original: "/images/gallery/jobfair/3.webp",
    thumbnail: "/images/gallery/jobfair/3.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
  },
  {
    original: "/images/gallery/jobfair/4.webp",
    thumbnail: "/images/gallery/jobfair/4.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
  },
];

const useStyles = makeStyles((theme) => ({
  carousel: {
    marginTop: 80,
  },
}));

const GalleryJobFair = ({}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        {/*Mobile*/}
        <Box display={{ xs: "block", sm: "none", lg: "none" }}>
          <ImageGallery
            className={classes.carousel}
            items={images}
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={true}
            slideDuration={1200}
          />
        </Box>
        {/*Tablet*/}
        <Box display={{ xs: "none", sm: "block", md: "block", lg: "none" }}>
          <ImageGallery
            className={classes.carousel}
            items={images}
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={true}
            slideDuration={1200}
          />
        </Box>

        {/*Desktop*/}
        <Box display={{ xs: "none", lg: "block" }}>
          <ImageGallery
            className={classes.carousel}
            items={images}
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={true}
            slideDuration={1200}
            thumbnailPosition="right"
          />
        </Box>
      </div>
    </React.Fragment>
  );
};

export default withRouter(GalleryJobFair);
