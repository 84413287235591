import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 0rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1rem 0rem 1rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 1.5rem 0rem 1.5rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 2rem 0rem 2rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 2.5rem 0rem 2.5rem",
    },
    [theme.breakpoints.only("xl")]: {
      padding: "0rem 4rem 0rem 4rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 16rem 0rem 16rem",
    },
  },
}));

const BrmhstraContainer = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.container}>{props.children}</div>
    </React.Fragment>
  );
};

export default BrmhstraContainer;
