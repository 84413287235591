import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InfoCard from "../sub-components/InfoCard";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
  },
  title: {
    padding: "0.5em 0em 0.5em 0em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  paperIcon: {
    fontSize: "1.5em",
    marginBottom: "2em",
  },
  pp: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
}));

const SocialCauses = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h4" color="primary" className={classes.title}>
          Social Causes
        </Typography>

        {/* Desktop */}
        {/* <Hidden smDown> */}
        <Grid
          spacing={3}
          justify="center"
          direction="row"
          alignItems="center"
          container
        >
          <Grid xs={12} sm={6} md={4} item>
            <InfoCard
              className={classes.paperIcon}
              icon={
                <img src="../images/icons/socialcauses/environmental_protectionn.svg"></img>
              }
              title="Environmental Protection"
              text="Has improved the maintenance and infrastructure
                    in all parks through active participation of residents' welfare association."
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <InfoCard
              className={classes.paperIcon}
              icon={
                <img src="../images/icons/socialcauses/Girl Power Symbol.svg"></img>
              }
              title="Women Safety & Empowerment"
              text="Has installed proper street lighting on low-light roads to
                    ensure the safety and security of women and children."
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <InfoCard
              className={classes.paperIcon}
              icon={
                <img src="../images/icons/socialcauses/Traffic Management.svg"></img>
              }
              title="Traffic Management"
              text="Has helped decongest of arterial roads, radial roads and junctions
                   including Silk Board Junction, St. Johns Junction and Hosur Road Junction."
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <InfoCard
              className={classes.paperIcon}
              icon={
                <img src="../images/icons/socialcauses/educationn.svg"></img>
              }
              title="Education"
              text="Has improved the facilities of Government schools including quality of classrooms,
                    sporting facilities, labs, etc."
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <InfoCard
              className={classes.paperIcon}
              icon={
                <img src="../images/icons/socialcauses/Civic Infrastructure.svg"></img>
              }
              title="Civic Infrastructure"
              text="Has Upgraded BWSSB lines to ensure proper and sufficient supply of water to all."
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <InfoCard
              className={classes.paperIcon}
              icon={
                <img src="../images/icons/socialcauses/health_caree.svg"></img>
              }
              title="Health Care"
              text="Has implemented all government health schemes effectively."
            />
          </Grid>
        </Grid>
        {/* </Hidden> */}

        {/* Tab Phone */}
        {/* <Hidden mdUp>
          <Grid
            spacing={2}
            justify="center"
            direction="row"
            alignItems="center"
            container
          >
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/environmental_protection.svg"></img>
                }
                title="Environmental Protection"
                text="Improve the maintenance and infrastructure
                    in all parks through active participation of residents’ welfare association."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/Girl Power Symbol.svg"></img>
                }
                title="Women Safety & Empowerment"
                text="Proper street lighting on low-light roads will be installed to
                    ensure the safety and security of women and children."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/Traffic Management.svg"></img>
                }
                title="Traffic Management"
                text="Decongestion of Arterial roads, radial roads and junctions
                   including Silk Board Junction, St. Johns Junction and Hosur Road Junction."
              />
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            justify="center"
            direction="row"
            alignItems="center"
            container
          >
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/education.svg"></img>
                }
                title="Education"
                text="Facility of Government schools to be improved including quality of classrooms,
                    sporting facilities, labs, etc."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/Civic Infrastructure.svg"></img>
                }
                title="Civic Infrastructure"
                text="Upgradation of current BWSSB lines to ensure proper and sufficient supply of water to all."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/health_caree.svg"></img>
                }
                title="Health Care"
                text="All government health schemes to be implemented effectively."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/environmental_protection.svg"></img>
                }
                title="Environmental Protection"
                text="Improve the maintenance and infrastructure
                    in all parks through active participation of residents’ welfare association."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/Girl Power Symbolt.svg"></img>
                }
                title="Women Safety & Empowerment"
                text="Proper street lighting on low-light roads will be installed to
                    ensure the safety and security of women and children."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/Traffic Management.svg"></img>
                }
                title="Traffic Management"
                text="Decongestion of Arterial roads, radial roads and junctions
                   including Silk Board Junction, St. Johns Junction and Hosur Road Junction."
              />
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            justify="center"
            direction="row"
            alignItems="center"
            container
          >
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/education.svg"></img>
                }
                title="Education"
                text="Facility of Government schools to be improved including quality of classrooms,
                    sporting facilities, labs, etc."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/Civic Infrastructure.svg"></img>
                }
                title="Civic Infrastructure"
                text="Upgradation of current BWSSB lines to ensure proper and sufficient supply of water to all."
              />
            </Grid>
            <Grid xs={12} sm={4} item>
              <InfoCard
                className={classes.paperIcon}
                icon={
                  <img src="../images/icons/socialcauses/health_care.svg"></img>
                }
                title="Health Care"
                text="All government health schemes to be implemented effectively."
              />
            </Grid>
          </Grid>
        </Hidden> */}
      </div>
    </React.Fragment>
  );
};

export default SocialCauses;
