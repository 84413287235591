/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Container } from "@material-ui/core";
import Layout from "../components/layout/Layout";
import Carousel from "../components/page-sections/Carousel";
import About from "../components/page-sections/About";
import SocialCauses from "../components/page-sections/SocialCauses";
import Timeline from "../components/page-sections/Timeline";
import Gallery from "../components/page-sections/Gallery";
import GetInTouch from "../components/page-sections/GetInTouch";
import SocialMedia from "../components/page-sections/SocialMedia";

const useStyles = makeStyles((theme) => ({
  scrollMargin: {
    scrollMarginTop: "3rem",
  },
}));

const Index = () => {
  const classes = useStyles();
  return (
    <Layout>
      <React.Fragment>
        <span id="back-to-top-anchor"></span>
        <Carousel />
        <Container>
          <span id="about-anchor" className={classes.scrollMargin}></span>
          <About />
          {/* <span
            id="social-media-anchor"
            className={classes.scrollMargin}
          ></span>
          <SocialMedia /> */}
          <span
            id="social-causes-anchor"
            className={classes.scrollMargin}
          ></span>
          <SocialCauses />
          <span id="timeline-anchor" className={classes.scrollMargin}></span>
          <Timeline />
          <span id="gallery-anchor" className={classes.scrollMargin}></span>
          <Gallery />
          <span
            id="get-in-touch-anchor"
            className={classes.scrollMargin}
          ></span>
          <GetInTouch />
          <br />
        </Container>
      </React.Fragment>
    </Layout>
  );
};

export default Index;
