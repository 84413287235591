import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import { Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#04aa6d",
    },
    secondary: {
      main: "#046800",
    },
    error: {
      main: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
    },
    textPrimary: {
      main: "#000000",
    },
    textSecondary: {
      main: "#666666",
    },
  },
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    padding: "26px 16px",
    width: "100%",
    color: "#000000",
    backgroundColor: "#E8E8E8",
    // border: "5px solid #000000"
    // borderRadius: "5px",
    "&:hover": {
      color: "#ffffff",
      // background: "#a4508b",
      background: "linear-gradient(to right, #2b5876, #4e4376)",
    },
  },
  paperRTL: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    padding: "26px 16px",
    width: "100%",
    color: "#000000",
    backgroundColor: "#E8E8E8",
    "&:hover": {
      color: "#ffffff",
      // backgroundColor: "#a4508b",
      background: "linear-gradient(to right, #2b5876, #4e4376)",
    },
  },
  title: {
    padding: "0.5em 0em 0.5em 0em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography
          align="center"
          variant="h4"
          color="primary"
          className={classes.title}
        >
          Timeline
        </Typography>

        <Hidden smUp>
          <ThemeProvider theme={theme}>
            <Timeline align="left">
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Congress leader.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.content}>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2023</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Won the Karnataka Assembly Elections 8 Times
                        continuously; currently MLA of BTM Constituency
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Congress leader.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.content}>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2021</b>
                    </Typography>
                    <Typography variant="p">
                      <b>Ramalinga Reddy appointed KPCC Working President</b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/election.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.content}>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2018</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Won the Karnataka Assembly Elections 7 Times
                        continuously
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/home minister.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.content}>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2017</b>
                    </Typography>
                    <Typography variant="p">
                      <b>Ramalinga Reddy appointed Home Minister (2017)</b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/transport.svg"></img>
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b> 2013</b>
                    </Typography>
                    <Typography variant="p">
                      {" "}
                      <b>
                        Minister As Transport Department In Sri. Siddaramaiah’s
                        Government
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Member Of Karnataka Pradesh.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2008</b>
                    </Typography>
                    <Typography variant="p">
                      <b>Elected as MLA from BTM Constituency(2008)</b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/graduationp.svg"></img>
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2004</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Minister For Primary And Secondary Education In Sri
                        N.Dharma Singh’s Government (2004 – 2006)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/founded_paradigmp.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>2002</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Minister For Food & Civil Supplies And Bangalore City
                        Development In Sri S.M.Krishna’s Government (2002 –
                        2004)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Congress leader.svg"></img>
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b> 2001</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Member For All India Congress Committee (2001 – 2016)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/President In Jayanagar.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>1999</b>
                    </Typography>
                    <Typography variant="p">
                      <b>Elected as MLA from Jayanagar Constituency(1999)</b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Elected as MLA.svg"></img>
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>1994</b>
                    </Typography>
                    <Typography variant="p">
                      <b>Elected as MLA from Jayanagar Constituency(1994)</b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Chairman For Agricultural.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>1993</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Chairman For Agricultural Produce Marketing Committee
                        (1993 – 1994)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/State Minister At Finance.svg"></img>
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b> 1992</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        State Minister At Finance, Major & Medium Scale
                        Industries And APMC In Sri. Veerappa Mouly’s Govt (1992
                        – 1994)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Chairman For Agricultural.svg"></img>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b>1990</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        Member Of Karnataka Pradesh Congress Committee (1990 –
                        2006)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/President In Jayanagar.svg"></img>
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1">
                      <b> 1989</b>
                    </Typography>
                    <Typography variant="p">
                      <b>Elected as MLA from Jayanagar Constituency(1989)</b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="grey" variant="outlined">
                    <img src="../images/icons/timeline/Congress leader.svg"></img>
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h5" component="h1" color="grey">
                      <b>1985</b>
                    </Typography>
                    <Typography variant="p">
                      <b>
                        President In Jayanagar Block Congress Committee (1985 –
                        1990)
                      </b>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </ThemeProvider>
        </Hidden>

        <Hidden xsDown>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/election.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.content}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2023</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      Won the Karnataka Assembly Elections 8 Times continuously;
                      currently MLA of BTM Constituency
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Congress leader.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.content}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2021</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Ramalinga Reddy appointed KPCC Working President</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/election.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.content}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2018</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      Won the Karnataka Assembly Elections 7 Times continuously
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/home minister.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.content}>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2017</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Ramalinga Reddy appointed Home Minister (2017)</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/transport.svg"></img>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b> 2013</b>
                  </Typography>
                  <Typography variant="p">
                    {" "}
                    <b>
                      Minister As Transport Department In Sri. Siddaramaiah’s
                      Government
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Member Of Karnataka Pradesh.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2008</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Elected as MLA from BTM Constituency(2008)</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/graduationp.svg"></img>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2004</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      Minister For Primary And Secondary Education In Sri
                      N.Dharma Singh’s Government (2004 – 2006)
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/founded_paradigmp.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>2002</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Minister For Food & Civil Supplies </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Congress leader.svg"></img>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b> 2001</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Member For All India Congress Committee (2001 – 2016)</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/President In Jayanagar.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>1999</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Elected as MLA from Jayanagar Constituency(1999)</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Elected as MLA.svg"></img>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>1994</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Elected as MLA from Jayanagar Constituency(1994)</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Chairman For Agricultural.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>1993</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      Chairman For Agricultural Produce Marketing Committee
                      (1993 – 1994)
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/State Minister At Finance.svg"></img>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b> 1992</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      State Minister At Finance, Major & Medium Scale Industries
                      And APMC In Sri. Veerappa Mouly’s Govt (1992 – 1994)
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Chairman For Agricultural.svg"></img>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b>1990</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      Member Of Karnataka Pradesh Congress Committee (1990 –
                      2006)
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/President In Jayanagar.svg"></img>
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1">
                    <b> 1989</b>
                  </Typography>
                  <Typography variant="p">
                    <b>Elected as MLA from Jayanagar Constituency(1989)</b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="grey" variant="outlined">
                  <img src="../images/icons/timeline/Congress leader.svg"></img>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h5" component="h1" color="grey">
                    <b>1985</b>
                  </Typography>
                  <Typography variant="p">
                    <b>
                      President In Jayanagar Block Congress Committee (1985 –
                      1990)
                    </b>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Hidden>
      </div>
    </React.Fragment>
  );
}
