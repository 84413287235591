/* Common Imports */

import React from "react";

/* Component Imports */

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

const CheckBox = ({
  id,
  name,
  className,
  disabled = false,
  label,
  helperText,
  checked,
  icon,
  checkedIcon,
  style,
  onChange,
  required,
  error,
}) => {
  return (
    <React.Fragment>
      <FormControlLabel
        id={id}
        disabled={disabled}
        label={label}
        className={className}
        required={required}
        error={error}
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={name}
            icon={icon}
            checkedIcon={checkedIcon}
            color="primary"
            style={style}
          />
        }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </React.Fragment>
  );
};

export default CheckBox;
