import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1em",
    height: "19.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "17em",
    },
  },
  paperTitle: {
    marginTop: "0.7rem",
    marginLeft: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2em",
    },
  },
  paperTitleOpposite: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  iconTitleRow: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  body1: {
    padding: "0.5em",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#666666",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
}));

const InfoCard = ({ icon, title, text, variant = "default" }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.iconTitleRow}>
          {icon}
          <Typography
            variant="h6"
            color="textPrimary"
            className={
              variant == "oppositeTitle"
                ? classes.paperTitleOpposite
                : classes.paperTitle
            }
          >
            {title}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.body1}>
          {text}
        </Typography>
      </Paper>
    </React.Fragment>
  );
};

export default InfoCard;
