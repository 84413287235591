import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Link } from "@material-ui/core";
import Contactform from "./Contactform";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
  },
  title: {
    padding: "0.5em 0em 0.5em 0em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  body1: {
    padding: "0.5em",
    color: "black",
  },
  getInTouch: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  responsiveMap: {
    overflow: "hidden",
    paddingBottom: "56.25%",
    position: "relative",
    height: "100",
  },
  responsiveMapIFrame: {
    left: "0",
    top: "0",
    height: "100%",
    width: "100%",
    position: "absolute",
    border: "0",
    borderRadius: "4px",
  },
}));

const GetInTouch = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h4" color="primary" className={classes.title}>
          Get In Touch
        </Typography>

        {/* Desktop */}
        <Grid
          container
          spacing={3}
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid xs={12} lg={6} item>
            <div>
              <Grid xs={12} item>
                <center>
                  <iframe
                    // className={classes.responsiveMapIFrame}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31107.536929191006!2d77.598061!3d12.943537000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x46d2ca02c5e54a5f!2sRamalinga%20Reddy%20Office!5e0!3m2!1sen!2sin!4v1618657384122!5m2!1sen!2sin"
                    frameBorder="0"
                    // height="400"
                    // width="600"
                    height="400"
                    width="100%"
                    allowFullScreen={true}
                    aria-hidden="false"
                    tabIndex="0"
                    title="Ramalinga Reddy's Office"
                  ></iframe>
                </center>
              </Grid>

              <Grid xs={12} item>
                <br />
                <Typography variant="h6" color="black">
                  Head Office:
                </Typography>
                <Typography variant="body1" color="black">
                  455/7, 15th Cross Rd, Lakkasandra,
                  <br />
                  Wilson Garden, Bengaluru,
                  <br />
                  Karnataka 560030
                </Typography>
                <br />
                <Typography variant="h6" color="black">
                  Phone Number
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <Link
                    href="tel:+918026542424"
                    rel="noopener"
                    title="Call Ramalinga Reddy's Office"
                    aria-label="Call Ramalinga Reddy's Office"
                  >
                    080 2223 7240
                  </Link>
                </Typography>
                <br />
                <Typography variant="h6" color="black">
                  Email Address
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <Link
                    href="mailto:connect@Ramalingareddy.in"
                    rel="noopener"
                    title="Call Ramalinga Reddy's Office"
                    aria-label="Call Ramalinga Reddy's Office"
                  >
                    ramalingareddyofficial@gmail.com
                  </Link>
                </Typography>
              </Grid>
            </div>
          </Grid>

          <Grid xs={12} lg={6} className={classes.getInTouch} item>
            <Contactform />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default GetInTouch;
