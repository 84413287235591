/* React Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

/* Component Imports */
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/navbar/Navbar";
import BrmhstraContainer from "../../components/layout/BrmhstraContainer";
import AppConfig from "../../AppConfig";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  contactPaper: {
    padding: theme.spacing(1),
    backgroundColor: "#ffffff",
    marginRight: "10px",
    zIndex: "26",
  },
  phoneButton: {
    borderRadius: "10px",
    // background: "linear-gradient(to right, #2b5876, #4e4376)",
    backgroundColor: "#4648A0",
    "&:hover": {
      backgroundColor: "#4648A0",
    },
    color: "#ffffff",
  },
  emailButton: {
    borderRadius: "10px",
    // background: "linear-gradient(to right, #2b5876, #4e4376)",
    backgroundColor: "#4648A0",
    "&:hover": {
      backgroundColor: "#4648A0",
    },
    color: "#ffffff",
    textTransform: "lowercase",
  },
  fabTop: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: "2",
    // background: "linear-gradient(to right, #2b5876, #4e4376)",
    backgroundColor: "#4648A0",
    "&:hover": {
      backgroundColor: "#4648A0",
    },
    color: "#ffffff",
  },
  fabPhone: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: "2",
    // background: "linear-gradient(to right, #2b5876, #4e4376)",
    backgroundColor: "#4648A0",
    "&:hover": {
      backgroundColor: "#4648A0",
    },
    color: "#ffffff",
  },
  phoneIcon: {
    fontSize: "medium",
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Layout = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;
  const handleClickPhone = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            props.keywords
              ? props.keywords
              : "ramalinga,reddy,rlr,btm,layout,constituency,brmhstra,politics,india,karnataka,home,minister,mla,jayanagar"
          }
          description={
            props.description
              ? props.description
              : "Ramalinga Reddy has been the pillar of Karnataka politics and has contributed to the state’s progress for over 40 years. He has dedicated most part of his life for the service of the people of Karnataka. He is an accessible, simple, humble, trusted, accountable and experienced leader."
          }
          title={props.title ? props.title : "Ramalinga Reddy"}
          canonicalUrl={
            props.canonicalUrl
              ? props.canonicalUrl
              : AppConfig.urlPrefix + props.router.asPath.substring(1)
          }
        />
        <Navbar />
        <Helmet>
          <html lang="en-GB" />
        </Helmet>
        <BrmhstraContainer>{props.children}</BrmhstraContainer>

        <ScrollTop {...props}>
          <Fab
            className={classes.fabTop}
            size="small"
            aria-label="scroll back to top"
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <Fab
          size="small"
          className={classes.fabPhone}
          aria-label="contact"
          onClick={handleClickPhone}
        >
          <PhoneIcon className={classes.phoneIcon} />
        </Fab>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="left-end"
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent",
            },
            arrow: {
              enabled: true,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={10} className={classes.contactPaper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      className={classes.phoneButton}
                      startIcon={<PhoneIcon />}
                      fullWidth
                      href="tel:080 2223 7240"
                    >
                      080 2223 7240
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      className={classes.emailButton}
                      startIcon={<EmailIcon />}
                      fullWidth
                      href="mailto:ramalingareddyofficial@gmail.com"
                    >
                      ramalingareddyofficial@gmail.com
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Fade>
          )}
        </Popper>

        <Footer id="footer" />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
