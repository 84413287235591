import React from "react";
import { withRouter } from "next/router";
import ImageGallery from "react-image-gallery";
import styles from "react-image-gallery/styles/css/image-gallery.css";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const images = [
  {
    original: "/images/carousel/1.webp",
    thumbnail: "/images/carousel/1m.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
    imageSet: [
      {
        srcSet: "/images/carousel/1m.webp",
        media: "(max-width: 701px)",
      },
      {
        srcSet: "/images/carousel/1.webp",
        media: "(min-width: 700px)",
      },
    ],
  },
  {
    original: "/images/carousel/2.webp",
    thumbnail: "/images/carousel/2m.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
    imageSet: [
      {
        srcSet: "/images/carousel/2m.webp",
        media: "(max-width: 768px)",
      },
      {
        srcSet: "/images/carousel/2.webp",
        media: "(min-width: 769px)",
      },
    ],
  },
  {
    original: "/images/carousel/3.webp",
    thumbnail: "/images/carousel/3m.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
    imageSet: [
      {
        srcSet: "/images/carousel/3m.webp",
        media: "(max-width: 768px)",
      },
      {
        srcSet: "/images/carousel/3.webp",
        media: "(min-width: 769px)",
      },
    ],
  },
  {
    original: "/images/carousel/4.webp",
    thumbnail: "/images/carousel/4m.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
    imageSet: [
      {
        srcSet: "/images/carousel/4m.webp",
        media: "(max-width: 768px)",
      },
      {
        srcSet: "/images/carousel/4.webp",
        media: "(min-width: 769px)",
      },
    ],
  },
  {
    original: "/images/carousel/5.webp",
    thumbnail: "/images/carousel/5m.webp",
    originalAlt: "Unable to load image",
    thumbnailAlt: "Unable to load image",
    originalTitle: "Ramalinga Reddy",
    thumbnailTitle: "Ramalinga Reddy",
    imageSet: [
      {
        srcSet: "/images/carousel/5m.webp",
        media: "(max-width: 768px)",
      },
      {
        srcSet: "/images/carousel/5.webp",
        media: "(min-width: 769px)",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0.1rem",
    // height: "10rem"
  },
  carousel: {
    // marginTop: 80,
  },
}));

const Carousel = ({}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        {/*Mobile*/}
        <Box display={{ xs: "block", sm: "none", lg: "none" }}>
          <ImageGallery
            className={classes.carousel}
            items={images}
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={true}
            slideDuration={1800}
            slideInterval={6000}
          />
        </Box>
        {/*Tablet*/}
        <Box display={{ xs: "none", sm: "block", md: "block", lg: "none" }}>
          <ImageGallery
            className={classes.carousel}
            items={images}
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={true}
            slideDuration={1800}
            slideInterval={6000}
          />
        </Box>

        {/*Desktop*/}
        <Box display={{ xs: "none", lg: "block" }}>
          <ImageGallery
            className={classes.carousel}
            items={images}
            showPlayButton={false}
            lazyLoad={true}
            autoPlay={false}
            slideDuration={1800}
            slideInterval={6000}
          />
        </Box>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Carousel);
