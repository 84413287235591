/* Common Imports */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "../../sub-components/Iconbutton";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#31326F",
    marginTop: "1rem",
    padding: theme.spacing(2),
  },
  copyright: {
    marginTop: "1rem",
    marginBottom: "0rem",
  },
  root: {
    padding: "0.75em",
    width: "100%",
    alignItems: "center",
    background: "black",
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  centerDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      padding: "0.55em",
    },
  },
  leftMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
      padding: "0.55em",
      alignItems: "center",
      // spacing: "10",
      // margin:"0em 0.8em 0.6em 0em",
    },
  },
  link: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  socialDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em",
  },
  iconBtn: {
    padding: "6px",
    "&:hover": {
      backgroundColor: "#4648A0",
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <br />
        <Container>
          <Box className={classes.footerContainer}>
            <Typography variant="h6" align="center" color="error" noWrap>
              <b>Follow us on social media</b>
            </Typography>

            <div className={classes.socialDiv}>
              <IconButton
                ariaLabel="Facebook"
                className={classes.iconBtn}
                component="button"
                type="submit"
                href="https://www.facebook.com/ramalingareddyofficial"
                target="blank"
                margin="0em 1.2em 0em 0em"
                size="small"
                icon="social/facebook"
              />
              <IconButton
                ariaLabel="Twitter"
                className={classes.iconBtn}
                component="button"
                type="submit"
                href="https://twitter.com/RLR_BTM?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                target="blank"
                margin="0em 1.2em 0em 0em"
                size="small"
                icon="social/twitter"
              />
              <IconButton
                ariaLabel="Instagram"
                className={classes.iconBtn}
                component="button"
                type="submit"
                href="https://www.instagram.com/rlrbtm/"
                target="blank"
                margin="0em 0em 0em 0em"
                size="small"
                icon="social/instagram"
              />
            </div>
            <Typography
              className={classes.leftMobile}
              variant="body2"
              align="center"
              color="error"
              noWrap
            >
              &copy; {new Date().getFullYear()} <b></b>
              <span>Ramalinga Reddy. All Rights Reserved</span>
            </Typography>
            <Typography
              className={classes.centerDesktop}
              variant="body2"
              color="error"
              noWrap
            >
              &copy; {new Date().getFullYear()} <b></b> Ramalinga Reddy. All
              Rights Reserved
            </Typography>
            <br />
          </Box>
        </Container>
      </Paper>
    </React.Fragment>
  );
};

export default Footer;
