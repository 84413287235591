/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  primarybutton: {
    margin: theme.spacing(1),
    background: "linear-gradient(90deg, #F74134 0%, #EB1C61 100%)",
    borderRadius: "5",
    color: "#ffffff",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    textTransform: "capitalize",
    "&:hover": {
      background: "linear-gradient(90deg, #CC3A30 0%, #C31D54 100%)",
    },
    "&:disabled": {
      background: "#9E9E9E",
      color: "#CFD8DC",
    },
  },
  disabled: {
    background: "#BDBDBD",
  },
}));

const Primary = ({
  id,
  variant,
  label,
  size,
  className,
  startIcon,
  endIcon,
  href,
  onClick,
  margin,
  disabled,
  component,
  type,
  rel,
  download,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Button
        id={id}
        variant={variant}
        size={size}
        classes={{ disabled: classes.disabled }}
        className={`${classes.primarybutton} ${className}`}
        href={href}
        component={component ? component : undefined}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        style={{ margin: margin ? margin : undefined }}
        disabled={disabled ? disabled : undefined}
        type={type ? type : undefined}
        rel={rel ? rel : "noopener"}
        download={download ? download : undefined}
      >
        {label}
      </Button>
    </React.Fragment>
  );
};

export default Primary;
