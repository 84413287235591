import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import IconButton from "@material-ui/core/IconButton";

const DispenseIcon = (icon = "action/pdf") => {
  switch (icon) {
    case "social/facebook":
      return "/images/icons/social/facebook.svg";
    case "social/instagram":
      return "/images/icons/social/instagram.svg";
    case "social/twitter":
      return "/images/icons/social/twitter.svg";
    case "social/linkedin":
      return "/images/icons/social/linkedin.svg";
    case "social/menu":
      return "/images/icons/social/menu.svg";
    default:
      return "/images/icons/action/pdf.svg";
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  iconButton: {
    
    backgroundColor: "#4648A0",

    borderRadius: "5px",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    "& .MuiTouchRipple-root span": {
      borderRadius: "5px",
    },
  },
}));
const IconBtn = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <IconButton
        id={props.id ? props.id : undefined}
        aria-label={props.ariaLabel ? props.ariaLabel : "Button"}
        className={`${classes.iconButton} ${props.className}`}
        size={props.size ? props.size : "medium"}
        style={{ margin: props.margin ? props.margin : "0em" }}
        href={props.href ? props.href : ""}
        component={props.component ? props.component : undefined}
        onClick={props.onClick}
        disabled={props.disabled ? props.disabled : undefined}
        type={props.type ? props.type : undefined}
        target={props.target ? props.target : undefined}
        rel={props.rel ? props.rel : "noopener"}
      >
        <img
          decode="async"
          alt="/images/icons/social/location.svg"
          loading="lazy"
          referrerPolicy="no-referrer"
          height={20}
          width={20}
          // className="injectable"
          src={DispenseIcon(props.icon ? props.icon : "social/facebook")}
        />
      </IconButton>
    </React.Fragment>
  );
};

export default IconBtn;