/* Common Imports */

import React from "react";
import { withRouter } from "next/router";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

/* Component Imports */

import TextField from "@material-ui/core/TextField";

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          /* underline color when textfield is active */
          borderBottom: "2px solid #666666",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          /* underline color when textfield is hovered */
          borderBottom: `2px solid #666666`,
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  muilabel: {
    marginBottom: "1.3rem",
    "& label.Mui-focused": {
      color: "#666666",
    },
    "& label": {
      color: "#757575",
    },
  },
}));

const TextArea = ({
  id,
  name,
  className,
  required = false,
  disabled = false,
  label,
  defaultValue,
  helperText,
  placeholder,
  variant,
  size,
  rows,
  rowsMax,
  maxLength,
  minLength,
  hc,
}) => {
  const classes = useStyles();

  /* Error Handling & Validation*/
  const [error = false, setError] = React.useState(false);
  const [errortext, setErrorText] = React.useState("");

  function handleChange(event) {
    if (event.target.required == true && event.target.value == "") {
      setError(true);
      setErrorText("This field is required");
    } else if (
      event.target.attributes["maxLength"].value !== "" &&
      event.target.value.length == event.target.attributes["maxLength"].value
    ) {
      setError(true);
      setErrorText(
        "Maximum " + event.target.attributes["maxLength"].value + " characters"
      );
    } else if (
      event.target.attributes["minLength"].value !== "" &&
      event.target.value.length < event.target.attributes["minLength"].value
    ) {
      setError(true);
      setErrorText(
        "Minimum " + event.target.attributes["minLength"].value + " characters"
      );
    } else {
      setError(false);
      setErrorText("");
    }
    hc(event, error);
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <TextField
          id={id}
          fullWidth
          name={name}
          className={`${classes.muilabel} ${className}`}
          required={required}
          disabled={disabled}
          label={label}
          defaultValue={defaultValue}
          error={error}
          helperText={error ? errortext : helperText}
          placeholder={placeholder}
          variant={variant}
          onChange={handleChange}
          size={size}
          multiline
          rows={rows}
          rowsMax={rowsMax}
          inputProps={{
            maxLength: maxLength == undefined ? 999 : maxLength,
            minLength: minLength == undefined ? 0 : minLength,
          }}
        />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withRouter(TextArea);
