/* Common Imports */

import React from "react";
import { withRouter } from "next/router";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

/* Component Imports */

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          /* underline color when textfield is active */
          borderBottom: "2px solid #666666",
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          /* underline color when textfield is hovered */
          borderBottom: `2px solid #666666`,
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  muilabel: {
    "& label.Mui-focused": {
      color: "#666666",
    },
    "& label": {
      color: "#757575",
    },
  },
}));

var Ref = React.createRef();

var validatorArray3 = ["isNumber", "minStringLength: 10", "maxStringLength:10"];
var validatorArray2 = ["isNumber", "maxStringLength:10"];

var errorMessagesArray3 = [
  "Please enter a valid phone number",
  "Please enter a 10 digit phone number",
  "Please enter a 10 digit phone number",
];

var errorMessagesArray2 = [
  "Please enter a valid phone number",
  "Please enter a 10 digit phone number",
];

const PhoneNumber = ({
  id,
  className,
  pattern,
  required = false,
  disabled = false,
  label,
  defaultValue,
  helperText,
  placeholder,
  variant,
  size,
  hc,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const [vA, setVA] = React.useState(validatorArray2);
  const [eM, setEM] = React.useState(errorMessagesArray2);
  const [cC, setCC] = React.useState(0);
  const [error, setError] = React.useState(false);
  const handleChange = (event) => {
    if (event.target.value.length >= 10) setCC(10);
    else if (event.target.value.length <= 1) setCC(0);
    else setCC(event.target.value.length);
    errorMessagesArray2[1] = errorMessagesArray2[1].substring(0, 36);
    errorMessagesArray3[1] = errorMessagesArray3[1].substring(0, 36);
    errorMessagesArray3[2] = errorMessagesArray3[2].substring(0, 36);
    errorMessagesArray2[1] += " [" + (cC + 1) + " / 10]";
    errorMessagesArray3[1] += " [" + (cC + 1) + " / 10]";
    errorMessagesArray3[2] += " [" + (cC + 1) + " / 10]";
    if (event.target.value.length < 1) {
      setVA(validatorArray2);
      setEM(errorMessagesArray2);
    } else {
      setVA(validatorArray3);
      setEM(errorMessagesArray3);
    }
    if (
      /^\d+$/.test(event.target.value.charAt(event.target.value.length - 1))
    ) {
      if (event.target.value.length > 10) {
        setInputValue(
          event.target.value.substring(0, event.target.value.length - 1)
        );
      } else {
        setInputValue(event.target.value);
      }
      if (event.target.value.length <= 10)
        Ref.current.validate(event.target.value);
    } else {
      if (event.target.value.length < 1) setInputValue("");
      else
        setInputValue(
          event.target.value.substring(0, event.target.value.length - 1)
        );
    }
    if (event.target.value.length < 10) setError(true);
    else setError(false);
    hc(event, error);
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <ValidatorForm onSubmit={() => {}}>
          <TextValidator
            id={id}
            fullWidth
            className={`${classes.muilabel} ${className}`}
            pattern={pattern}
            required={required}
            disabled={disabled}
            label={label}
            defaultValue={defaultValue}
            helperText={
              helperText
                ? helperText + " [" + cC + " / 10]"
                : " " + cC + " / 10"
            }
            placeholder={placeholder}
            variant={variant}
            size={size}
            ref={Ref}
            value={inputValue}
            onChange={handleChange}
            validators={vA}
            errorMessages={eM}
          />
        </ValidatorForm>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default withRouter(PhoneNumber);
