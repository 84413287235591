import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "../sub-components/Iconbutton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
  },
  title: {
    padding: "0.8em 0em 0.5em 0em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  body1: {
    padding: "0.5em",
  },
  flexC: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SocialMedia = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid
          direction="row"
          spacing={3}
          justify="center"
          alignItems="center"
          container
        >
          <Grid className={classes.flexC} xs={1} sm={1} md={3} item>
            <IconButton
              ariaLabel="Download PDF"
              component="button"
              type="submit"
              href="https://facebook.com/sowmyareddyofficial"
              target="blank"
              margin="0em 1.2em 0em 0em"
              size="medium"
              icon="social/facebook"
            />
            <IconButton
              ariaLabel="Download PDF"
              component="button"
              type="submit"
              href="https://twitter.com/Sowmyareddyr"
              target="blank"
              margin="0em 1.2em 0em 0em"
              size="medium"
              icon="social/twitter"
            />
            <IconButton
              ariaLabel="Download PDF"
              component="button"
              type="submit"
              href="https://linkedin.com/in/sowmya-reddy-2b936a7/"
              target="blank"
              margin="0em 1.2em 0em 0em"
              size="medium"
              icon="social/linkedin"
            />
            <IconButton
              ariaLabel="Download PDF"
              component="button"
              type="submit"
              href="https://www.instagram.com/sowmyareddy_official/"
              target="blank"
              margin="0em 0em 0em 0em"
              size="medium"
              icon="social/instagram"
            />
          </Grid>
          {/* <Grid className={classes.flexC} xs={6} sm={6} md={3} item>
          <Link
            href="https://twitter.com/Sowmyareddyr"
            rel="noopener"
            title="Follow Sowmya Reddy on Twitter"
            aria-label="Follow Sowmya Reddy on Twitter"
            target="_blank"
          >
            <TwitterFilled />
          </Link>
        </Grid>
        <Grid className={classes.flexC} xs={6} sm={6} md={3} item>
          <Link
            href="https://youtube.com/channel/UCEzkpOu3p-Z0kRiS_xIJsGA/videos"
            rel="noopener"
            title="Follow Sowmya Reddy on Youtube"
            aria-label="Follow Sowmya Reddy on Youtube"
            target="_blank"
          >
            <YoutubeFilled />
          </Link>
        </Grid>
        <Grid className={classes.flexC} xs={6} sm={6} md={3} item>
          <Link
            href="https://linkedin.com/in/sowmya-reddy-2b936a7/"
            rel="noopener"
            title="Follow Sowmya Reddy on Linkedin"
            aria-label="Follow Sowmya Reddy on Linkedin"
            target="_blank"
          >
            <LinkedinFilled />
          </Link>
        </Grid> */}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default SocialMedia;
