import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ImageButton from "../../components/sub-components/ImageButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import GalleryWelfare from "../sub-components/GalleryWelfare";
import GalleryCulture from "../sub-components/GalleryCulture";
import GalleryTransportation from "../sub-components/GalleryTransportation";
import GalleryInfrastructure from "../sub-components/GalleryInfrastructure";
import GalleryEco from "../sub-components/GalleryEco";
import GalleryJobFair from "../../components/sub-components/GalleryJobFair";
import GalleryWaterConservation from "../sub-components/GalleryWaterConservation";
import GalleryCovid from "../../components/sub-components/GalleryCovid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
  },
  title: {
    padding: "0.5em 0em 0.5em 0em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  body1: {
    padding: "0.5em",
  },
  responsiveImg: {
    height: "auto",
    width: "100%",
    borderRadius: "5px",
  },

  appBar: {
    position: "relative",
    // color:"#1A8739",
    background: "linear-gradient(to right, #2b5876, #4e4376)",
    color: "#ffffff",
  },
  title2: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Gallery = (props) => {
  const classes = useStyles();

  const [openWelfare, setOpenWelfare] = useState(false);
  const [openInfrastructure, setOpenInfrastructure] = useState(false);
  const [openTransportation, setOpenTransportation] = useState(false);
  const [openCulture, setOpenCulture] = useState(false);
  const [openEco, setOpenEco] = useState(false);
  const [openJobFair, setOpenJobFair] = useState(false);
  const [openWaterConservation, setOpenWaterConservation] = useState(false);
  const [openCovid19, setOpenCovid19] = useState(false);

  const handleClickOpenWelfare = () => {
    setOpenWelfare(true);
  };
  const handleClickOpenInfrastructure = () => {
    setOpenInfrastructure(true);
  };
  const handleClickOpenTransportation = () => {
    setOpenTransportation(true);
  };
  const handleClickOpenCulture = () => {
    setOpenCulture(true);
  };
  const handleClickOpenEco = () => {
    setOpenEco(true);
  };
  const handleClickOpenJobFair = () => {
    setOpenJobFair(true);
  };

  const handleClickOpenWaterConservation = () => {
    setOpenWaterConservation(true);
  };
  const handleClickOpenCovid19 = () => {
    setOpenCovid19(true);
  };

  const handleCloseWelfare = () => {
    setOpenWelfare(false);
  };
  const handleCloseInfrastructure = () => {
    setOpenInfrastructure(false);
  };
  const handleCloseTransportation = () => {
    setOpenTransportation(false);
  };
  const handleCloseCulture = () => {
    setOpenCulture(false);
  };
  const handleCloseEco = () => {
    setOpenEco(false);
  };
  const handleCloseJobFair = () => {
    setOpenJobFair(false);
  };
  const handleCloseWaterConservation = () => {
    setOpenWaterConservation(false);
  };
  const handleCloseCovid19 = () => {
    setOpenCovid19(false);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h4" color="primary" className={classes.title}>
          Gallery
        </Typography>

        {/* Welfare */}
        <Dialog
          fullScreen
          open={openWelfare}
          onClose={handleCloseWelfare}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseWelfare}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Welfare
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryWelfare />
        </Dialog>

        {/* Infrastructure */}
        <Dialog
          fullScreen
          open={openInfrastructure}
          onClose={handleCloseInfrastructure}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseInfrastructure}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Infrastructure
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryInfrastructure />
        </Dialog>

        {/* Transportation */}
        <Dialog
          fullScreen
          open={openTransportation}
          onClose={handleCloseTransportation}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseTransportation}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Transportation
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryTransportation />
        </Dialog>

        {/* Culture */}
        <Dialog
          fullScreen
          open={openCulture}
          onClose={handleCloseCulture}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseCulture}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Culture
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryCulture />
        </Dialog>

        {/* Eco */}
        <Dialog
          fullScreen
          open={openEco}
          onClose={handleCloseEco}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseEco}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Environment
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryEco />
        </Dialog>

        {/* Job Fair */}
        <Dialog
          fullScreen
          open={openJobFair}
          onClose={handleCloseJobFair}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseJobFair}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Job Fair
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryJobFair />
        </Dialog>

        {/* Water Conservation */}
        <Dialog
          fullScreen
          open={openWaterConservation}
          onClose={handleCloseWaterConservation}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseWaterConservation}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                Water Conservation
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryWaterConservation />
        </Dialog>

        {/* Covid */}
        <Dialog
          fullScreen
          open={openCovid19}
          onClose={handleCloseCovid19}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseCovid19}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title2}>
                COVID-19
              </Typography>
            </Toolbar>
          </AppBar>
          <GalleryCovid />
        </Dialog>

        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Welfare"
              url="/images/gallery/welfare/2.webp"
              onClick={handleClickOpenWelfare}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Infrastructure"
              url="/images/gallery/infrastructure/1.webp"
              onClick={handleClickOpenInfrastructure}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Transportation"
              url="/images/gallery/transportation/4.webp"
              onClick={handleClickOpenTransportation}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Culture"
              url="/images/gallery/culture/2.webp"
              onClick={handleClickOpenCulture}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Environment"
              url="/images/gallery/eco/3.webp"
              onClick={handleClickOpenEco}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Job Fair"
              url="/images/gallery/jobfair/3.webp"
              onClick={handleClickOpenJobFair}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Water Conservation"
              url="/images/gallery/waterconservation/8.webp"
              onClick={handleClickOpenWaterConservation}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ImageButton
              title="Covid 19"
              url="/images/gallery/covid19/1.webp"
              onClick={handleClickOpenCovid19}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Gallery;
