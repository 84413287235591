const MODE = process.env.mode;

var tempAc = {};

MODE == "development"
  ? (tempAc = {
      urlPrefix: process.env.baseUrlDevelopment,
      apiUrlPrefix: process.env.baseUrlDevelopment + "api/",
    })
  : (tempAc = {
      urlPrefix: process.env.baseUrlProduction,
      apiUrlPrefix: process.env.baseUrlProduction + "api/",
    });
const AppConfig = tempAc;

export default AppConfig;
