import { Grid, Hidden } from "@material-ui/core";
import React from "react";
import SchoolIcon from "@material-ui/icons/School";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "../sub-components/Iconbutton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
  },
  title: {
    padding: "0.5em 0em 0.5em 0em",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  body1: {
    padding: "0.5em",
    display: "flex",
    flexDirection: "flex-start",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  responsiveImg: {
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  about: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse !important",
    },
  },
  subtittle: {
    fontWeight: "600",
    marginBottom: "1rem",
  },
  aboutContentDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "space-evenly",
  },
  flexC: {
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  iconBtn: {
    backgroundColor: "#4648A0",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#4648A0",
    },
  },
}));

const About = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography
          align="center"
          variant="h4"
          color="primary"
          className={classes.title}
        >
          About Ramalinga Reddy
        </Typography>

        <Grid
          container
          spacing={2}
          className={classes.about}
          justify="space-between"
          alignItems="center"
        >
          <Grid xs={12} md={6} item>
            <Typography
              variant="body1"
              className={classes.body1}
              color="textSecondary"
            >
              Ramalinga Reddy has been the pillar of Karnataka politics and has
              contributed to the state&apos;s progress for over 40 years. Having
              secured his eighth consecutive victory as an MLA, he stands as one
              of the most senior and experienced politicians in southern India.
              He has dedicated most part of his life for the service of the
              people of Karnataka. He is an accessible, simple, humble, trusted,
              accountable and experienced leader.
            </Typography>
            <Typography
              variant="body1"
              className={classes.body1}
              color="textSecondary"
            >
              He maintains a consistent presence at his office daily from 8:30
              am, displaying patience and a keen interest in addressing the
              concerns of the public promptly. His extensive knowledge of
              Bangalore&apos;s city network and his ties with people from
              diverse backgrounds across Karnataka have played a pivotal role in
              his unchallenged political career.
            </Typography>
            <div className={classes.flexC}>
              <IconButton
                ariaLabel="Facebook"
                component="button"
                type="submit"
                href="https://www.facebook.com/ramalingareddyofficial"
                target="blank"
                margin="0em 1.2em 0em 0em"
                className={classes.iconBtn}
                size="medium"
                icon="social/facebook"
              />
              <IconButton
                ariaLabel="Twitter"
                component="button"
                type="submit"
                href="https://twitter.com/RLR_BTM?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                target="blank"
                margin="0em 1.2em 0em 0em"
                className={classes.iconBtn}
                size="medium"
                icon="social/twitter"
              />
              <IconButton
                ariaLabel="Instagram"
                component="button"
                type="submit"
                href="https://www.instagram.com/rlrbtm/"
                target="blank"
                margin="0em 1.2em 0em 0em"
                className={classes.iconBtn}
                size="medium"
                icon="social/instagram"
              />
            </div>
          </Grid>
          <Grid xs={12} md={6} item>
            <img
              src="/images/about.webp"
              alt="/images/about.webp"
              height="884"
              width="1000"
              className={classes.responsiveImg}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default About;
