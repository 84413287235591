import { withRouter } from "next/router";
import Head from "next/head";
import React from "react";
import AppConfig from "../../AppConfig";

const Header = ({ router, keywords, description, title, canonicalUrl }) => {
  return (
    <React.Fragment>
      <Head>
        <link
          rel="preconnect"
          href="https://ramalingareddy.in/"
          crossOrigin="true"
        />
        <link rel="preconnect" href="https://google.com" crossOrigin="true" />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="true"
        />
        <link
          rel="preconect"
          href="https://trc.taboola.com"
          crossOrigin="true"
        />
        <link
          rel="preconect"
          href="https://p.adsymptotic.com"
          crossOrigin="true"
        />
        <link
          rel="preconect"
          href="https://connect.facebook.net"
          crossOrigin="true"
        />
        <link
          rel="preconect"
          href="https://www.google-analytics.com"
          crossOrigin="true"
        />
        <link
          rel="preconect"
          href="https://snap.licdn.com"
          crossOrigin="true"
        />
        <link
          rel="canonical"
          href={
            canonicalUrl
              ? canonicalUrl
              : AppConfig.urlPrefix + router.asPath.substring(1)
          }
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="cache-control" content="Private" />
        <meta httpEquiv="Expires" content="31536000" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#CC0000" />
        <meta
          name="Description"
          content={
            description
              ? description
              : "Ramalinga Reddy has been the pillar of Karnataka politics and has contributed to the state’s progress for over 40 years. He has dedicated most part of his life for the service of the people of Karnataka. He is an accessible, simple, humble, trusted, accountable and experienced leader."
          }
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="keywords"
          content={
            keywords
              ? keywords
              : "ramalinga,reddy,rlr,btm,layout,constituency,brmhstra,politics,india,karnataka,home,minister,mla,jayanagar"
          }
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content={title ? title : "Ramalinga Reddy"}
        />
        <meta property="og:title" content={title ? title : "Ramalinga Reddy"} />
        <meta
          property="og:description"
          content={
            description
              ? description
              : "Ramalinga Reddy has been the pillar of Karnataka politics and has contributed to the state’s progress for over 40 years. He has dedicated most part of his life for the service of the people of Karnataka. He is an accessible, simple, humble, trusted, accountable and experienced leader."
          }
        />
        <meta property="og:url" content="https://ramalingareddy.in/" />
        <meta property="og:image" content="/images/icons/logo/og_512.jpg" />
        <meta property="og:image:alt" content="/images/icons/logo/og_512.jpg" />
        <meta
          property="og:image:secure_url"
          content="/images/icons/logo/og_512.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:locale" content="en_GB" />
        <link rel="icon" href="/images/icons/logo/favicon_48.png" />
        <link
          rel="shortcut icon"
          type="image/png"
          href="/images/icons/logo/favicon_48.png"
        />
        <link
          rel="apple-touch-icon"
          href="/images/icons/logo/favicon_192.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <title>{title ? title : "Ramalinga Reddy"}</title>
      </Head>
    </React.Fragment>
  );
};

export default withRouter(Header);
