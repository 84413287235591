import { withRouter } from "next/router";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "../../sub-components/Iconbutton";
// import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import FaceIcon from "@material-ui/icons/Face";
// import EmojiSymbolsIcon from "@material-ui/icons/EmojiSymbols";
import TimelineIcon from "@material-ui/icons/Timeline";
import CollectionsIcon from "@material-ui/icons/Collections";
// import ShareIcon from "@material-ui/icons/Share";
import ContactsIcon from "@material-ui/icons/Contacts";
// import Logo from "../../images/logo/Logo";
// import LogoSideNav from "../../images/logo/LogoSideNavNew";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(3),
    background: "white",
    [theme.breakpoints.up("lg")]: {
      display: "none !important",
    },
  },
  title: {
    flexGrow: 1,
  },
  navbar: {
    backgroundColor: "#FFFFFF",
    color: "#068200",
  },
  toolbar: {
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "65%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "63%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "75%",
    },
    display: "flex",
    [theme.breakpoints.up(theme.breakpoints.values.md + 80)]: {
      justifyContent: "space-between",
    },
  },
  hnav: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hnavButton: {
    fontWeight: 500,
    color: "#666666",
    "&:hover": {
      color: "#04aa6d",
      // textDecoration: "underline",
    },
  },
  list: {
    width: 240,
    marginTop: theme.spacing(4),
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconBtn: {
    padding: "6px",
  },
  contactBtn: {
    marginRight: "3em",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      margin: "0rem 0rem 0rem 1.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 35)]: {
      margin: "0rem 0rem 0rem 1.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 75)]: {
      margin: "0rem 0rem 0rem 2.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 131)]: {
      margin: "0rem 0rem 0rem 4rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 250)]: {
      margin: "0rem 0rem 0rem 8rem",
    },
    /*  Tablet breakpoints  */
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      margin: "0rem 0rem 0rem 15rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
      margin: "0rem 0rem 0rem 22rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 80)]: {
      margin: "0rem 0rem 0rem 0rem",
    },
  },
  logoDrawer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Navbar = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <LogoSideNav /> */}
      <div className={classes.logoDrawer}>
        <img src="../images/icons/social/RLRLogo.svg"></img>
      </div>
      <List>
        <Link href="#" rel="noopener" aria-label="Home" title="Home">
          <ListItem button>
            <ListItemIcon>
              <HomeIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        <Link
          href="#about-anchor"
          rel="noopener"
          aria-label="About"
          title="About"
        >
          <ListItem button>
            <ListItemIcon>
              <FaceIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
        </Link>
        {/* <Link
          href="#social-causes-anchor"
          rel="noopener"
          aria-label="Social Causes"
          title="Social Causes"
        >
          <ListItem button>
            <ListItemIcon>
              <EmojiSymbolsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Social Causes" />
          </ListItem>
        </Link> */}
        <Link
          href="#timeline-anchor"
          rel="noopener"
          aria-label="Timeline"
          title="Timeline"
        >
          <ListItem button>
            <ListItemIcon>
              <TimelineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Timeline" />
          </ListItem>
        </Link>
        <Link
          href="#gallery-anchor"
          rel="noopener"
          aria-label="Gallery"
          title="Gallery"
        >
          <ListItem button>
            <ListItemIcon>
              <CollectionsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Gallery" />
          </ListItem>
        </Link>
        {/* <Link
          href="#social-media-anchor"
          rel="noopener"
          aria-label="Social Media"
          title="Social Media"
        >
          <ListItem button>
            <ListItemIcon>
              <ShareIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Social Media" />
          </ListItem>
        </Link> */}
        <Link
          href="#get-in-touch-anchor"
          rel="noopener"
          aria-label="Get In Touch"
          title="Contact"
        >
          <ListItem button>
            <ListItemIcon>
              <ContactsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Get In Touch" />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <div>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </div>
      <div className={classes.root}>
        <ElevationScroll {...props}>
          <AppBar className={classes.navbar} position="fixed">
            <Toolbar className={classes.toolbar}>
              {/* <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer("left", true)}
                icon="social/menu"
              >

              </IconButton> */}
              <img
                src="../images/icons/social/menu.svg"
                onClick={toggleDrawer("left", true)}
                className={classes.menuButton}
              ></img>

              {/* <Logo /> */}
              <div className={classes.logo}>
                <img src="../images/icons/social/RLRLogo.svg"></img>
              </div>
              <div className={classes.hnav}>
                <Link
                  href=" "
                  rel="noopener"
                  aria-label="Home"
                  title="Home"
                  className={classes.link}
                >
                  <Button className={classes.hnavButton}>Home</Button>
                </Link>
                <Link
                  href="#about-anchor"
                  rel="noopener"
                  aria-label="About"
                  title="About"
                  className={classes.link}
                >
                  <Button color="inherit" className={classes.hnavButton}>
                    About
                  </Button>
                </Link>

                <Link
                  href="#timeline-anchor"
                  rel="noopener"
                  aria-label="Timeline"
                  title="Timeline"
                  className={classes.link}
                >
                  <Button color="inherit" className={classes.hnavButton}>
                    Timeline
                  </Button>
                </Link>
                <Link
                  href="#gallery-anchor"
                  rel="noopener"
                  aria-label="Gallery"
                  title="Gallery"
                  className={classes.link}
                >
                  <Button color="inherit" className={classes.hnavButton}>
                    Gallery
                  </Button>
                </Link>

                <Link
                  href="#get-in-touch-anchor"
                  rel="noopener"
                  margin="0em 0em 0em 0em"
                  aria-label="Get In Touch"
                  title="Get In Touch"
                  className={`${classes.link} ${classes.contactBtn}`}
                >
                  <Button color="inherit" className={classes.hnavButton}>
                    Contact
                  </Button>
                </Link>

                <IconButton
                  ariaLabel="Facebook"
                  component="button"
                  type="submit"
                  href="https://www.facebook.com/ramalingareddyofficial"
                  target="blank"
                  margin="0em 0.5em 0em 0em"
                  className={classes.iconBtn}
                  size="small"
                  icon="social/facebook"
                />
                <IconButton
                  ariaLabel="Twitter"
                  component="button"
                  type="submit"
                  href="https://twitter.com/RLR_BTM?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  target="blank"
                  margin="0em 0.5em 0em 0em"
                  className={classes.iconBtn}
                  size="small"
                  icon="social/twitter"
                />
                <IconButton
                  ariaLabel="LinkedIn"
                  component="button"
                  type="submit"
                  href="https://in.linkedin.com/in/ramalinga-reddy-321011227"
                  target="blank"
                  margin="0em 0.5em 0em 0em"
                  className={classes.iconBtn}
                  size="small"
                  icon="social/linkedin"
                />
                <IconButton
                  ariaLabel="Instagram"
                  component="button"
                  type="submit"
                  href="https://www.instagram.com/rlrbtm/"
                  target="blank"
                  margin="0em 0.5em 0em 0em"
                  className={classes.iconBtn}
                  size="small"
                  icon="social/instagram"
                />
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Navbar);
